<template>
  <div class="dataTable-footer justify-content-start">
    <div class="dataTable-footer-item dataTable-nav btn-pagination-chart">
      <button
        :class="
          !previousPayment || isDisabledPrevButton ? 'dataTable-nav-btn disabled' : 'dataTable-nav-btn'
        "
        :disabled="!previousPayment || isDisabledPrevButton"
        type="button"
        @click="startInterval--"
      >
        <i class="aikon aikon-chevron_left dataTable-nav-icon"></i>
      </button>
      <button
        :class="
          !startInterval || isDisabledNextButton ? 'dataTable-nav-btn disabled' : 'dataTable-nav-btn'
        "
        :disabled="!startInterval || isDisabledNextButton"
        type="button"
        @click="startInterval++"
      >
        <i class="aikon aikon-chevron_right dataTable-nav-icon"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interval: {
      type: String,
    },
    previousPayment: {
      type: Boolean,
    },
    isDisabledPrevButton: {
      type: Boolean,
    },
    isDisabledNextButton: {
      type: Boolean,
    }
  },
  data() {
    return {
      startInterval: 0,
    };
  },
  watch: {
    startInterval() {
      this.getPaymentAggregate();
    },
    interval() {
      this.startInterval = 0;
    },
  },
  methods: {
    getPaymentAggregate() {
      this.$emit('getPaymentAggregate', this.startInterval);
    },
  },
};
</script>
