<script>
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: ['chartData', 'options'], // propsに chartData と options を定義
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData(val) {
      this.renderChart(val, this.options)
    }
  }
}
</script>
