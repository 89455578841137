<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section v-if="isGmoOrOfficeLoginStoreAndStoreGroup" class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:body>
                <table class="table dataTable-collapse-default">
                  <tbody>
                    <tr v-for="item in dataTable" :key="item.index">
                      <template v-if="!($permission.isGroup() && item.notGroupFlag)">
                        <th>{{ item.label }}</th>
                        <td>{{ item.value }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>売上総額</template>
                  <template v-slot:num>{{ paymentTotal.amount | comma }}</template>
                  <template v-slot:sup>円</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <ChartWithInterval
              title="売上金額"
              type="amount"
              unit="円"
              ref="amountChart"
              :subdomain="subdomain"
              :actionPath="actionPath"
              :chartList="paymentAmountList"
              :chartTotal="paymentAmountTotal"
              :previousPayment="previousPaymentAmount"
            />
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <ChartWithInterval
              :title="useTicketFlag ? '利用枚数' : '受注件数'"
              type="count"
              :unit="useTicketFlag ? '枚' : '件'"
              ref="countChart"
              :subdomain="subdomain"
              :actionPath="actionPath"
              :chartList="paymentCountList"
              :chartTotal="paymentCountTotal"
              :previousPayment="previousPaymentCount"
            />
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RoleConstants } from '@/constants/role';
//helpers
import { formatLocalString } from '@/helpers/formatData';
//components
import NumDisplay from '@/components/NumDisplay.vue';
import ChartWithInterval from '@/components/ChartWithInterval.vue';
//mixins
import nav from '@/mixins/nav/summary';

export default {
  name: 'EventSummaryDetail',
  data: function() {
    return {
      pageName: '集計',
      chartCountData: {},
      chartAmountData: {},
      actionPath: 'aggregate/getPaymentAggregate',
      labelsTicket: ['cancelQuantityTicket']
    };
  },
  mixins: [nav],
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      paymentTotal: 'aggregate/paymentTotal',
      paymentAmountList: 'aggregate/paymentAmountList',
      paymentCountList: 'aggregate/paymentCountList',
      paymentAmountTotal: 'aggregate/paymentAmountTotal',
      paymentCountTotal: 'aggregate/paymentCountTotal',
      previousPaymentAmount: 'aggregate/previousPaymentAmount',
      previousPaymentCount: 'aggregate/previousPaymentCount',
      isClientChild: 'auth/isClientChild',
      isShowCurrency: 'event/isShowCurrency',
      newSubdomain: 'common/subdomain',
      shopDetail: 'shop/shopDetail',
      commonSubdomain: 'auth/commonSubdomain',
      commonShopId: 'auth/commonShopId',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
      useTicketFlag: 'event/useTicketFlag',
    }),
    subdomain() {
      if (this.isGmoOrOfficeLoginStore) return this.newSubdomain;
      return this.$route.params.subdomain || this.infor.event?.subdomain;
    },
    sumTotal() {
      return this.paymentTotal.amount + this.paymentTotal.cancel;
    },
    isCheckRoleShop() {
      const isClientChildNoShopRole = this.isClientChild && !this.infor?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE);
      const isClientNoShopRole = this.$permission.isOffice() && !this.infor?.role?.includes(RoleConstants.ROLE_SHOPS_OFFICE);
      return isClientChildNoShopRole || (isClientNoShopRole && !this.isShowCurrency);
    },
    shopName() {
      return this.isGmoOrOfficeLoginStore ? this.shopDetail?.name : this.infor?.shop?.name;
    },
    dataTable() {
      const data = {
        shopName: {
          label: '店舗名',
          value: this.shopName || '',
          notGroupFlag: true,
        },
        count: {
          label: this.useTicketFlag ? '利用枚数' : '利用件数',
          value: formatLocalString(this.paymentTotal.count),
        },
        sum: {
          label: '利用金額',
          value: '¥' + formatLocalString(this.sumTotal),
        },
        cancelQuantityTicket: {
          label: '返金枚数',
          value: formatLocalString(this.paymentTotal.cancelQuantityTicket),
        },
        cancel: {
          label: '返金額',
          value: '¥' + formatLocalString(this.paymentTotal.cancel),
        },
        amount: {
          label: '売上金額',
          value: '¥' + formatLocalString(this.paymentTotal.amount),
        }
      };
      if (this.$permission.isGroup()) {
        delete data.shopName;
      }
      if (!this.useTicketFlag) {
        Object.keys(data).forEach(el => {
          if(this.labelsTicket.includes(el)) {
            delete data[el];
          }
        })
      }
      return data;
    },
  },
  components: {
    NumDisplay,
    ChartWithInterval,
  },
  methods: {
    async getDetail() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        await this.$store.dispatch('shop/getShopDetail', {
          subdomain: this.commonSubdomain,
          id: this.commonShopId,
        });
      }
    },
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('aggregate/getPaymentAggregate', {
        subdomain: this.subdomain,
        isCheckRoleShop: this.isCheckRoleShop ? 1 : 0
      }),
      this.getDetail(),
      this.$refs.amountChart.getDataList(),
      this.$refs.countChart.getDataList(),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  }
};
</script>
