<template>
  <Panel type="board">
    <template v-slot:headline>{{ title }}</template>
    <template v-slot:tab>
      <ul class="tab">
        <li v-for="item in intervalList" :key="item.index" class="tab-item">
          <button 
            :class="{ 'tab-btn': true,'is-active': interval === item.value }" 
            type="button" 
            @click="tabChange(item.value)"
          >{{ item.label }}</button>
        </li>
      </ul>
    </template>
    <template v-slot:body>
      <div class="slide-body">
        <transition name="slide" mode="out-in">
          <div class="tab-content">
            <ChartLine
              class="chart-line"
              :chartData="data"
              :options="options"
            />
            <div class="chart-footer justify-content-between">
              <PaginationSummary
                :interval="interval"
                :previousPayment="previousPayment"
                @getPaymentAggregate="getDataList"
                :isDisabledPrevButton="isDisabledPrevButton"
                :isDisabledNextButton="isDisabledNextButton"
              ></PaginationSummary>
              <div class="d-flex align-items-center">
                <p class="chart-footer-title">{{ chartFooterTitle }}</p>
                <p class="chart-footer-num">{{ chartTotal | comma }}<span class="chart-footer-unit">{{ unit }}</span></p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </Panel>
</template>

<script>
//components
import ChartLine from '@/components/ChartLine.vue';
import PaginationSummary from '@/components/PaginationSummary.vue';
//constants
import { StatusConstants } from '@/constants/status';
//helpers
import { getDatesInMonthList, getMonthsInYearList, getDatesInWeekList } from '@/helpers/chartDate';
export default {
  data() {
    return {
      interval: StatusConstants.interval.weekly.value,
      chartFooterTitle: '週次合計',
      data: {},
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: { beginAtZero: true },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.unit;
            },
          },
          displayColors: false,
        },
      },
      startInterval: 0,
      isDisabledPrevButton: false,
      isDisabledNextButton: false,
    }
  },
  computed: {
    intervalList() {
      return Object.values(StatusConstants.interval)
    },
    year() {
      return getMonthsInYearList().map(item => item + '月');
    },
    month() {
      return getDatesInMonthList(this.startInterval).map(item => (item.getMonth() + 1)+ '/' + item.getDate());
    },
    week() {
      return getDatesInWeekList(this.startInterval).map(item => (item.getMonth() + 1) + '/' + item.getDate());
    },
  },
  props: {
    chartTotal: {
      type: Number,
    },
    chartList: {
      type: Array,
    },
    actionPath: {
      type: String,
      required: true,
    },
    subdomain: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    unit: {
      type: String,
      default: '',
    },
    previousPayment: {
      type: Boolean,
    }
  },
  components: {
    ChartLine,
    PaginationSummary,
  },
  methods: {
    async getDataList(startInterval) {
      this.startInterval = startInterval;
      await this.$store.dispatch(this.actionPath, {
        subdomain: this.subdomain,
        interval: this.interval,
        type: this.type || null,
        startInterval,
      });
      this.updatedChartData();
    },
    tabChange(interval) {
      this.interval = interval;
      const loading = this.$loading.show();
      Promise.all([
        this.getDataList(),
      ]).finally(() => {
        this.$loading.clear(loading);
      })
    },
    getLables(val) {
      if (val) {
        this.chartFooterTitle = StatusConstants.interval[val]?.totalLabel;
      }
      switch(val) {
        case StatusConstants.interval.weekly.value:
          return this.week;
        case StatusConstants.interval.monthly.value:
          return this.month;
        case StatusConstants.interval.yearly.value:
          return this.year;
        default:
          this.chartFooterTitle = StatusConstants.interval.weekly.totalLabel;
          return this.week;
      }
    },
    updatedChartData() {
      const labels = this.getLables(this.interval);
      this.data = {
        labels: labels,
        datasets: [
          {
            data: this.chartList,
            fill: false,
            borderColor: 'rgb(255, 128, 139)',
            pointBackgroundColor: 'rgb(255, 128, 139)',
            borderWidth: 3,
            lineTension: 0,
          },
        ],
      };
      this.isDisabledPrevButton = false;
      this.isDisabledNextButton = false;
    },
  },
  watch: {
    startInterval(newVal, oldVal) {
      if (newVal - oldVal > 0) {
        this.isDisabledNextButton = true;
      } else {
        this.isDisabledPrevButton = true;
      }
    }
  }
}
</script>
