import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {
      navs: [
        {
          id: '01',
          name: '店舗別集計',
          path: '/event/:subdomain/:directory/summary',
          routeName: 'EventSummary',
          includes: false,
        },
        {
          id: '02',
          name: '店舗別集計グラフ',
          path: '/event/:subdomain/:directory/summary/detail',
          routeName: 'EventSummaryDetail',
          includes: false,
        },
        {
          id: '03',
          name: '企業別集計',
          path: '/event/:subdomain/:directory/summary/company',
          routeName: 'EventSummaryCompany',
          includes: false,
        },
      ],
      storePageNavs: [
        {
          id: '01',
          name: '店舗別集計',
          path: '/shop/:subdomain/summary',
          routeName: 'ShopSummary',
          includes: false,
        },
        {
          id: '02',
          name: '店舗別集計グラフ',
          path: '/shop/:subdomain/summary/detail',
          routeName: 'ShopSummaryDetail',
          includes: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild',
      hasShopRole: 'auth/hasShopRole',
      isShowCurrency: 'event/isShowCurrency',
      hasAggregateRole: 'auth/hasAggregateRole',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
    }),
    pageNavs() {
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) return this.storePageNavs;
      if ((this.isClientChild || !this.isShowCurrency || !this.hasAggregateRole) && !this.$permission.isStoreOrGroup()) return this.navs.slice(0, 2);
      if (!this.hasShopRole) return this.navs.slice(-1);
      return this.navs;
    }
  },
};
